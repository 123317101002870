//Initialization
const { REACT_APP_API_URL } = process.env

export const getImage = filePath => fetch(`${REACT_APP_API_URL}/api/content/getimage`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    body: JSON.stringify({
        filePath
    })
})

export const getContent = async props => {
    return new Promise (async (resolve, reject) => {
        try {
            const response = await fetch(`${REACT_APP_API_URL}/api/content/getcontent`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(props)
            })
            const output = await response.json()
            console.log(output)
            resolve(output)
        } catch(e) {
            reject(false)
        }
    })
}