import React, { useEffect } from 'react'

const Bookmarks = () => {
    useEffect (() => {
        document.title = 'Chaturbyte - bookmarks'
    })
    return (
        <div>
            <h1>BOOKMARKS</h1>
        </div>
    )
}

export default Bookmarks