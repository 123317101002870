//Import modules
import jwt_decode from "jwt-decode"

//Initialization
const { REACT_APP_API_URL } = process.env

export const handleSignIn = async (email, password) => {
    try {
        const response = await fetch(`${REACT_APP_API_URL}/api/user/signin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email,
                password
            })
        })
        const payload = await response.json()

        //Handle errors
        const error = payload?.error
        if (error) {
            return {
                error
            }
        }

        //Save token to local storage or throw exception
        const token = payload?.data?.token
        if (!token) {
            throw Object.assign(new Error('No token in server response'), {code: 400})
        }
        localStorage.setItem('chaturbyteToken', token)

        //Decode token and save UUID to local storage
        const decoded = jwt_decode(token)
        const uuid = decoded?.uuid
        if (!uuid) {
            throw Object.assign(new Error('No uuid in received token'), {code: 400})
        }
        localStorage.setItem('chaturbyteUuid', uuid)

        return { 
            data: {
                chaturbyteToken: token,
                chaturbyteUuid: uuid
            }
        }

    } catch(e) {
        console.log(e)
        return {
            error: 'Failed to fetch response for sign in request'
        }
    }
}

export const handleSignUp = async (email, password) => {
    try {
        const response = await fetch(`${REACT_APP_API_URL}/api/user/signup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email,
                password
            })
        })
        const payload = await response.json()

        //Handle errors
        const error = payload?.error
        if (error) {
            return {
                error
            }
        }

        //Save token to local storage or throw exception
        const token = payload?.data?.token
        if (!token) {
            throw Object.assign(new Error('No token in server response'), {code: 400})
        }
        localStorage.setItem('chaturbyteToken', token)

        //Decode token and save UUID to local storage
        const decoded = jwt_decode(token)
        const uuid = decoded?.uuid
        if (!uuid) {
            throw Object.assign(new Error('No uuid in received token'), {code: 400})
        }
        localStorage.setItem('chaturbyteUuid', uuid)

        return { 
            data: {
                chaturbyteToken: token,
                chaturbyteUuid: uuid
            }
        }

    } catch(e) {
        console.log(e)
        return {
            error: 'Failed to fetch response for sign up request'
        }
    }
}

export const handleSignOut = () => {
    try {
        localStorage.removeItem('chaturbyteToken')
        localStorage.removeItem('chaturbyteUuid')

    } catch(e) {
        console.log('Failed to sign out')
    }
}

export const isAuthenticated = () => {
    try {
        return (localStorage.getItem('chaturbyteToken') && localStorage.getItem('chaturbyteUuid')) ? true : false
    } catch(e) {
        console.log('Failed to validate user authentication status')
        return false
    }
}
