//Import modules
import { useState } from 'react'

//Import components
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'
import Button from '@mui/material/Button'

const ContentFilterForm = ({filters, setFilters, setContent, setPage, setFetching}) => {

    const [tier1, setTier1] = useState(filters.tier1)
    const [tier2, setTier2] = useState(filters.tier2)
    const [tier3, setTier3] = useState(filters.tier3)
    const [tier4, setTier4] = useState(filters.tier4)
    const [tier5, setTier5] = useState(filters.tier5)
    const [unrated, setUnrated] = useState(filters.unrated)
    const [female, setFemale] = useState(filters.female)
    const [couple, setCouple] = useState(filters.couple)
    const [male, setMale] = useState(filters.male)
    const [trans, setTrans] = useState(filters.trans)
    const [search, setSearch] = useState(filters.search)
    const [unique, setUnique] = useState(filters.unique)

    return (
        <Paper
            style={{
                marginTop: 50,
                padding: 20,
                textAlign: 'center'
            }}
        >
            <Stack
                direction='row'
                spacing={10}
                textAlign="left"
                alignItems='top'
            >   
                <FormControl 
                    component='fieldset'
                    variant='standard'
                >
                    <FormLabel component="tiers">Tiers:</FormLabel>
                    <FormGroup>
                        <FormControlLabel 
                            control={
                                <Switch 
                                    checked={tier1} 
                                    name='tier1Switch' 
                                    onChange={(event) => setTier1(event.target.checked)} 
                                />
                            }
                            label='Tier 1'
                        />
                        <FormControlLabel 
                            control={
                                <Switch 
                                    checked={tier2} 
                                    name='tier2Switch' 
                                    onChange={(event) => setTier2(event.target.checked)} 
                                />
                            }
                            label='Tier 2'
                        />
                        <FormControlLabel 
                            control={
                                <Switch 
                                    checked={tier3} 
                                    name='tier3Switch' 
                                    onChange={(event) => setTier3(event.target.checked)} 
                                />
                            }
                            label='Tier 3'
                        />
                        <FormControlLabel 
                            control={
                                <Switch 
                                    checked={tier4} 
                                    name='tier4Switch' 
                                    onChange={(event) => setTier4(event.target.checked)} 
                                />
                            }
                            label='Tier 4'
                        />
                        <FormControlLabel 
                            control={
                                <Switch 
                                    checked={tier5} 
                                    name='tier5Switch' 
                                    onChange={(event) => setTier5(event.target.checked)} 
                                />
                            }
                            label='Tier 5'
                        /> 
                        <FormControlLabel 
                            control={
                                <Switch 
                                    checked={unrated} 
                                    name='unratedSwitch' 
                                    onChange={(event) => setUnrated(event.target.checked)} 
                                />
                            }
                            label='Not rated'
                        />                       
                    </FormGroup>
                </FormControl>

                <FormControl 
                    component='fieldset'
                    variant='standard'
                >
                    <FormLabel component="genders">Genders:</FormLabel>
                    <FormGroup>
                        <FormControlLabel 
                            control={
                                <Switch 
                                    checked={female} 
                                    name='femaleSwitch' 
                                    onChange={(event) => setFemale(event.target.checked)} 
                                />
                            }
                            label='Female'
                        />
                        <FormControlLabel 
                            control={
                                <Switch 
                                    checked={couple} 
                                    name='coupleSwitch' 
                                    onChange={(event) => setCouple(event.target.checked)} 
                                />
                            }
                            label='Couple'
                        />
                        <FormControlLabel 
                            control={
                                <Switch 
                                    checked={male} 
                                    name='maleSwitch' 
                                    onChange={(event) => setMale(event.target.checked)} 
                                />
                            }
                            label='Male'
                        />
                        <FormControlLabel 
                            control={
                                <Switch 
                                    checked={trans} 
                                    name='transSwitch' 
                                    onChange={(event) => setTrans(event.target.checked)} 
                                />
                            }
                            label='Trans'
                        />
                    </FormGroup>
                </FormControl>
                <Stack
                    direction='col'
                    justifyContent='left'
                    alignItems='top'
                >
                    <FormControl 
                        component='fieldset'
                        variant='standard'
                    >
                        <FormLabel component="search">Search:</FormLabel>
                        <FormGroup>
                            <TextField 
                                id="standard-basic" 
                                label="Room" 
                                variant="standard" 
                                value={search}
                                onChange={event => {
                                    setSearch(event.target.value);
                                }}
                            />
                            <FormControlLabel 
                            control={
                                <Switch 
                                    checked={unique} 
                                    name='uniqueSwitch' 
                                    onChange={(event) => setUnique(event.target.checked)}
                                />
                            }
                            label='Uniqualize'
                        />
                        </FormGroup>
                    </FormControl>
                </Stack>
            </Stack>
            <Button 
                size="large"
                onClick={() => {
                    setFetching(true)
                    setContent([])
                    setPage(1)
                    setFilters({
                        tier1,
                        tier2,
                        tier3,
                        tier4,
                        tier5,
                        unrated,
                        female,
                        couple,
                        male,
                        trans,
                        search,
                        unique
                    })
                }}
            >REFRESH</Button>

        </Paper>
    )
}

export default ContentFilterForm