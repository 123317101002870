import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import LinearProgress from '@mui/material/LinearProgress'

//Content components
import ContentCard from '../components/contents/ContentCard'
import ContentFilterForm from '../components/contents/ContentFilterForm'

//Content API
import { getContent } from '../http/contentAPI.js'

const Content = () => {
    const [searchParams] = useSearchParams()
    const [count, setCount] = useState(0)
    const [content, setContent] = useState([])
    const [perPage, setPerPage] = useState(20)
    const [pagesCount, setPagesCount] = useState(1)
    const [page, setPage] = useState(1)
    const [fetching, setFetching] = useState(true)

    const tier1 = searchParams.get('tier1') === null ? true : searchParams.get('tier1')
    const tier2 = searchParams.get('tier2') === null ? true : searchParams.get('tier2')
    const tier3 = searchParams.get('tier3') === null ? true : searchParams.get('tier3')
    const tier4 = searchParams.get('tier4') === null ? true : searchParams.get('tier4')
    const tier5 = searchParams.get('tier5') === null ? true : searchParams.get('tier5')
    const unrated = searchParams.get('unrated') === null ? true : searchParams.get('unrated')
    const female = searchParams.get('female') === null ? true : searchParams.get('female')
    const couple = searchParams.get('couple') === null ? true : searchParams.get('couple')
    const male = searchParams.get('male') === null ? true : searchParams.get('male')
    const trans = searchParams.get('trans') === null ? true : searchParams.get('trans')
    const search = searchParams.get('search') === null ? '' : searchParams.get('search')
    const unique = searchParams.get('unique') === null ? false : searchParams.get('unique')

    const [filters, setFilters] = useState({
        tier1,
        tier2,
        tier3,
        tier4,
        tier5,
        unrated,
        female,
        couple,
        male,
        trans,
        search,
        unique
    })

    useEffect (() => {
        document.title = 'Chaturbyte - content'
    })

    useEffect (() => {
        getContent({
            uuid: localStorage.getItem('chaturbyteUuid'),
            page,
            perPage,
            filters
        })
            .then(response => {
                setPagesCount(Math.ceil(response.count / perPage))
                return response
            })
            .then(response => {
                setCount(response.count)
                setContent(response.rows)
                setFetching(false)
            })
    }, [page, perPage, filters])
    
    return (
        <div>
            {
                fetching ? <LinearProgress 
                    style={{
                        marginTop: 50
                    }}
                />
                : <ContentFilterForm filters={filters} setFilters={setFilters} setContent={setContent} setPage={setPage} setFetching={setFetching}/>
            }
            {content.map(props => <ContentCard props={props}/>)}
            <Stack spacing={2}
                alignItems="center"
                style={{
                    padding: 30
                }}
            >
                <Pagination 
                    count={pagesCount}
                    page={page} 
                    variant='outlined' 
                    shape='rounded'
                    onChange={(event, value) => {
                        setPage(value)
                        setContent([])
                    }}
                />
            </Stack>
        </div>
    )
}

export default Content