import React, { useEffect, useState } from 'react'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import { useNavigate } from 'react-router-dom'

const AuthForm = ({buttonText, switchText, switchRoute, handle}) => {

    const navigate = useNavigate()

    const [errorMessage, setErrorMessage] = useState('')
    const [payload, setPayload] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const submitHandle = async () => {
        const result = await handle(email, password)
        const { error, data } = result
        if (error) {
            setErrorMessage(error)
            setPayload('')
        }
        if (data) {
            setErrorMessage('')
            setPayload(data)
            setTimeout(() => {
                navigate('/')
            }, 2000)
        }
    }

    useEffect(() => {
        setErrorMessage('')
        setPayload('')
    }, [email, password])

    return (

        <Paper 
            elevation={1} 
            style={{
                padding: 15
            }}
        >
            <Stack 
                spacing={2}
                justifyContent="center"
                alignItems="center"
            >
                <TextField fullWidth id='auth-email' label='email' variant='outlined' onChange={e => setEmail(e.target.value)} />
                <TextField fullWidth id='auth-password' label='password' variant='outlined' type='password' onChange={e => setPassword(e.target.value)} />
                <Button fullWidth id='auth-submit' variant='contained' onClick={submitHandle}>{buttonText}</Button>
                <Link href={switchRoute} underline='none'>{switchText}</Link>
                {errorMessage ? <Typography variant='body2' color='crimson'>
                    {errorMessage}
                </Typography> : ''}
                {payload && !errorMessage ? 
                    <Stack 
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography variant='caption' color='GrayText'>{'Redirecting...'}</Typography>
                        <CircularProgress />
                    </Stack> : ''}
            </Stack>
        </Paper>

    )
}

export default AuthForm