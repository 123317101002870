import { useState, useEffect } from 'react'
import Link from '@mui/material/Link'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import PermIdentitySharpIcon from '@mui/icons-material/PermIdentitySharp'
import SdStorageSharpIcon from '@mui/icons-material/SdStorageSharp'
import Stack from '@mui/material/Stack'
import MaleSharpIcon from '@mui/icons-material/MaleSharp'
import FemaleSharpIcon from '@mui/icons-material/FemaleSharp'
import TransgenderSharpIcon from '@mui/icons-material/TransgenderSharp'
import QuestionMarkSharpIcon from '@mui/icons-material/QuestionMarkSharp'
import TimerSharpIcon from '@mui/icons-material/TimerSharp'
import AccessTimeSharpIcon from '@mui/icons-material/AccessTimeSharp'
import SlowMotionVideoSharpIcon from '@mui/icons-material/SlowMotionVideoSharp'
import LooksOneIcon from '@mui/icons-material/LooksOne'
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined'
import LooksTwoIcon from '@mui/icons-material/LooksTwo'
import LooksTwoOutlinedIcon from '@mui/icons-material/LooksTwoOutlined'
import Looks3Icon from '@mui/icons-material/Looks3'
import Looks3OutlinedIcon from '@mui/icons-material/Looks3Outlined'
import Looks4Icon from '@mui/icons-material/Looks4'
import Looks4OutlinedIcon from '@mui/icons-material/Looks4Outlined'
import Looks5Icon from '@mui/icons-material/Looks5'
import Looks5OutlinedIcon from '@mui/icons-material/Looks5Outlined'
import ArrowCircleDownSharpIcon from '@mui/icons-material/ArrowCircleDownSharp'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
import { blue } from '@mui/material/colors'

//Content APIs
import { getImage } from '../../http/contentAPI'

//Tier 1 APIs
import * as tier1API from '../../http/tier1API'

//Tier 2 APIs
import * as tier2API from '../../http/tier2API'

//Tier 3 APIs
import * as tier3API from '../../http/tier3API'

//Tier 4 APIs
import * as tier4API from '../../http/tier4API'

//Tier 5 APIs
import * as tier5API from '../../http/tier5API'

const ContentCard = ({props}) => {
    const [imgFetching, setImgFetching] = useState(true)
    const [tierFetching, setTierFetching] = useState(false)
    const [imgSrc, setImgSrc] = useState('')
    const [tier1, setTier1] = useState(props.tier1)
    const [tier2, setTier2] = useState(props.tier2)
    const [tier3, setTier3] = useState(props.tier3)
    const [tier4, setTier4] = useState(props.tier4)
    const [tier5, setTier5] = useState(props.tier5)


    useEffect(() => {
        getImage(props.capsLocal)
            .then(response => response.blob())
            .then(response => URL.createObjectURL(response))
            .then(response => setImgSrc(response))
            .catch(error => setImgSrc(''))
    }, [props.capsLocal])

    useEffect(() => {
        setImgFetching(imgSrc ? false : true)
    }, [imgSrc])

    const drawCategory = props => {
        let icons = []
        switch (props?.category) {
            case 'couple':
                icons = [<MaleSharpIcon />, <FemaleSharpIcon />]
            break

            case 'female':
                icons = [<FemaleSharpIcon />]
            break

            case 'male':
                icons = [<MaleSharpIcon />]
            break

            case 'trans':
                icons = [<TransgenderSharpIcon />]
            break

            default:
                icons = [<QuestionMarkSharpIcon />]
            break
        }

        return (
            <Stack
                direction='row'
                spacing={0}
                justifyContent="left"
                alignItems="center"
            >
                {icons}
                <Typography variant="body3" color="text.secondary">{props.category}</Typography>    
            </Stack>
        )
    }

    return (
        <Card 
            style={{
                marginTop: 50,
                textAlign: 'center'
            }}
        >
            {
                imgFetching 
                ? <CircularProgress 
                    style={{
                        margin: 20
                    }}
                />
                : <CardMedia component="img" image={imgSrc} />
            }
            <CardContent>
                <Stack
                    direction='row'
                    spacing={4}
                    justifyContent="left"
                    alignItems="center"
                >
                    <Stack
                        direction='row'
                        spacing={1}
                        justifyContent="left"
                        alignItems="center"
                    >
                        <PermIdentitySharpIcon />
                        <Link href={`/content/?search=${props.room}`} underline='none'>{props.room}</Link>          
                    </Stack>
                    <Stack
                        direction='row'
                        spacing={1}
                        justifyContent="left"
                        alignItems="center"
                    >
                        <SdStorageSharpIcon />
                        <Typography variant="body3" color="text.secondary">{props.size} Mb</Typography>                    
                    </Stack>
                    {drawCategory(props)}
                    <Stack
                        direction='row'
                        spacing={1}
                        justifyContent="left"
                        alignItems="center"
                    >
                        <TimerSharpIcon />
                        <Typography variant="body3" color="text.secondary">{props.duration}</Typography>                    
                    </Stack>
                    <Stack
                        direction='row'
                        spacing={1}
                        justifyContent="left"
                        alignItems="center"
                    >
                        <SlowMotionVideoSharpIcon />
                        <Typography variant="body3" color="text.secondary">{props.resolution}</Typography>                    
                    </Stack>
                    <Stack
                        direction='row'
                        spacing={1}
                        justifyContent="left"
                        alignItems="center"
                    >
                        <AccessTimeSharpIcon />
                        <Typography variant="body3" color="text.secondary">{props.createdAt.replace(/T/, ' ').replace(/\..+/, '')}</Typography>                    
                    </Stack>
                </Stack>
            </CardContent>
            <CardActions>
                <Stack
                    direction='row'
                    spacing={2}
                    justifyContent="left"
                    alignItems="center"
                >
                    {tier1 
                        ? <IconButton
                            disabled={imgFetching || tierFetching}
                        >
                            <LooksOneIcon 
                                fontSize="large" 
                                style={{
                                    color: blue[700],
                                    cursor: 'hand'
                                }} 
                                onClick={() => {
                                    setTierFetching(true)
                                    tier1API.remove(localStorage.getItem('chaturbyteUuid'), props.room)
                                        .then(response => {
                                            setTier1(!tier1)
                                            setTierFetching(false)
                                        })
                                }} 
                            />
                        </IconButton> 
                        : <IconButton
                            disabled={imgFetching || tierFetching}
                        >
                            <LooksOneOutlinedIcon 
                                fontSize="large"
                                style={{
                                    cursor: 'hand' 
                                }}
                                onClick={() => {
                                    setTierFetching(true)
                                    tier1API.add(localStorage.getItem('chaturbyteUuid'), props.room)
                                        .then(response => {
                                            if (tier2) {
                                                tier2API.remove(localStorage.getItem('chaturbyteUuid'), props.room)
                                                setTier2(!tier2)
                                            }
                                            if (tier3) {
                                                tier3API.remove(localStorage.getItem('chaturbyteUuid'), props.room)
                                                setTier3(!tier3)
                                            }
                                            if (tier4) {
                                                tier4API.remove(localStorage.getItem('chaturbyteUuid'), props.room)
                                                setTier4(!tier4)
                                            }
                                            if (tier5) {
                                                tier5API.remove(localStorage.getItem('chaturbyteUuid'), props.room)
                                                setTier5(!tier5)
                                            }
                                            return response
                                        })
                                        .then(response => {
                                            setTier1(!tier1)
                                            setTierFetching(false)
                                        })
                                        .catch(error => {
                                            console.log(error)
                                        })
                                }} 
                            />
                        </IconButton>    
                    }
                    {tier2 
                        ? <IconButton
                            disabled={imgFetching || tierFetching}
                        >
                            <LooksTwoIcon 
                                fontSize="large" 
                                style={{
                                    color: blue[700],
                                    cursor: 'hand'
                                }} 
                                onClick={() => {
                                    setTierFetching(true)
                                    tier2API.remove(localStorage.getItem('chaturbyteUuid'), props.room)
                                        .then(response => {
                                            setTier2(!tier2)
                                            setTierFetching(false)
                                        })
                                }} 
                            />
                        </IconButton> 
                        : <IconButton
                            disabled={imgFetching || tierFetching}
                        >
                            <LooksTwoOutlinedIcon 
                                fontSize="large"
                                style={{
                                    cursor: 'hand' 
                                }}
                                onClick={() => {
                                    setTierFetching(true)
                                    tier2API.add(localStorage.getItem('chaturbyteUuid'), props.room)
                                        .then(response => {
                                            if (tier1) {
                                                tier1API.remove(localStorage.getItem('chaturbyteUuid'), props.room)
                                                setTier1(!tier1)
                                            }
                                            if (tier3) {
                                                tier3API.remove(localStorage.getItem('chaturbyteUuid'), props.room)
                                                setTier3(!tier3)
                                            }
                                            if (tier4) {
                                                tier4API.remove(localStorage.getItem('chaturbyteUuid'), props.room)
                                                setTier4(!tier4)
                                            }
                                            if (tier5) {
                                                tier5API.remove(localStorage.getItem('chaturbyteUuid'), props.room)
                                                setTier5(!tier5)
                                            }
                                            return response
                                        })
                                        .then(response => {
                                            setTier2(!tier2)
                                            setTierFetching(false)
                                        })
                                        .catch(error => {
                                            console.log(error)
                                        })
                                }} 
                            />
                        </IconButton>
                    }
                    {tier3 
                        ? <IconButton
                            disabled={imgFetching || tierFetching}
                        >
                            <Looks3Icon 
                                fontSize="large" 
                                style={{
                                    color: blue[700],
                                    cursor: 'hand'
                                }} 
                                onClick={() => {
                                    setTierFetching(true)
                                    tier3API.remove(localStorage.getItem('chaturbyteUuid'), props.room)
                                        .then(response => {
                                            setTier3(!tier3)
                                            setTierFetching(false)
                                        })
                                }} 
                            /> 
                        </IconButton>
                        : <IconButton
                            disabled={imgFetching || tierFetching}
                        >
                            <Looks3OutlinedIcon 
                                fontSize="large"
                                style={{
                                    cursor: 'hand' 
                                }}
                                onClick={() => {
                                    setTierFetching(true)
                                    tier3API.add(localStorage.getItem('chaturbyteUuid'), props.room)
                                        .then(response => {
                                            if (tier1) {
                                                tier1API.remove(localStorage.getItem('chaturbyteUuid'), props.room)
                                                setTier1(!tier1)
                                            }
                                            if (tier2) {
                                                tier1API.remove(localStorage.getItem('chaturbyteUuid'), props.room)
                                                setTier1(!tier1)
                                            }
                                            if (tier4) {
                                                tier4API.remove(localStorage.getItem('chaturbyteUuid'), props.room)
                                                setTier4(!tier4)
                                            }
                                            if (tier5) {
                                                tier5API.remove(localStorage.getItem('chaturbyteUuid'), props.room)
                                                setTier5(!tier5)
                                            }
                                            return response
                                        })
                                        .then(response => {
                                            setTier3(!tier3)
                                            setTierFetching(false)
                                        })
                                        .catch(error => {
                                            console.log(error)
                                        })
                                }} 
                            />
                        </IconButton>
                    }
                    {tier4 
                        ? <IconButton
                            disabled={imgFetching || tierFetching}
                        >
                            <Looks4Icon 
                                fontSize="large" 
                                style={{
                                    color: blue[700],
                                    cursor: 'hand'
                                }} 
                                onClick={() => {
                                    setTierFetching(true)
                                    tier4API.remove(localStorage.getItem('chaturbyteUuid'), props.room)
                                        .then(response => {
                                            setTier4(!tier4)
                                            setTierFetching(false)
                                        })
                                }} 
                            />
                        </IconButton> 
                        : <IconButton
                            disabled={imgFetching || tierFetching}
                        >
                            <Looks4OutlinedIcon 
                                fontSize="large"
                                style={{
                                    cursor: 'hand' 
                                }}
                                onClick={() => {
                                    setTierFetching(true)
                                    tier4API.add(localStorage.getItem('chaturbyteUuid'), props.room)
                                        .then(response => {
                                            if (tier1) {
                                                tier1API.remove(localStorage.getItem('chaturbyteUuid'), props.room)
                                                setTier1(!tier1)
                                            }
                                            if (tier2) {
                                                tier1API.remove(localStorage.getItem('chaturbyteUuid'), props.room)
                                                setTier1(!tier1)
                                            }
                                            if (tier3) {
                                                tier3API.remove(localStorage.getItem('chaturbyteUuid'), props.room)
                                                setTier3(!tier3)
                                            }
                                            if (tier5) {
                                                tier5API.remove(localStorage.getItem('chaturbyteUuid'), props.room)
                                                setTier5(!tier5)
                                            }
                                            return response
                                        })
                                        .then(response => {
                                            setTier4(!tier4)
                                            setTierFetching(false)
                                        })
                                        .catch(error => {
                                            console.log(error)
                                        })
                                }} 
                            />
                        </IconButton>
                    }
                    {tier5 
                        ? <IconButton
                            disabled={imgFetching || tierFetching}
                        >
                            <Looks5Icon 
                                fontSize="large" 
                                style={{
                                    color: blue[700],
                                    cursor: 'hand'
                                }} 
                                onClick={() => {
                                    setTierFetching(true)
                                    tier5API.remove(localStorage.getItem('chaturbyteUuid'), props.room)
                                        .then(response => {
                                            setTier5(!tier5)
                                            setTierFetching(false)
                                        })
                                }} 
                            />
                        </IconButton> 
                        : <IconButton
                            disabled={imgFetching || tierFetching}
                        >
                            <Looks5OutlinedIcon 
                                fontSize="large"
                                style={{
                                    cursor: 'hand' 
                                }}
                                onClick={() => {
                                    setTierFetching(true)
                                    tier5API.add(localStorage.getItem('chaturbyteUuid'), props.room)
                                        .then(response => {
                                            if (tier1) {
                                                tier1API.remove(localStorage.getItem('chaturbyteUuid'), props.room)
                                                setTier1(!tier1)
                                            }
                                            if (tier2) {
                                                tier1API.remove(localStorage.getItem('chaturbyteUuid'), props.room)
                                                setTier1(!tier1)
                                            }
                                            if (tier3) {
                                                tier3API.remove(localStorage.getItem('chaturbyteUuid'), props.room)
                                                setTier3(!tier3)
                                            }
                                            if (tier4) {
                                                tier4API.remove(localStorage.getItem('chaturbyteUuid'), props.room)
                                                setTier4(!tier4)
                                            }
                                            return response
                                        })
                                        .then(response => {
                                            setTier5(!tier5)
                                            setTierFetching(false)
                                        })
                                        .catch(error => {
                                            console.log(error)
                                        })
                                }} 
                            />
                        </IconButton>
                    }
                    <IconButton
                        color='secondary'
                        href={props.url}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <ArrowCircleDownSharpIcon 
                            fontSize="large" 
                            style={{
                                cursor: 'hand' 
                            }}
                        />
                    </IconButton>
                </Stack>
            </CardActions>
        </Card>
    )
}

export default ContentCard