//Import modules
import * as React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import AppBar from '@mui/material/AppBar'
import CssBaseline from '@mui/material/CssBaseline'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded'
import MovieCreationRoundedIcon from '@mui/icons-material/MovieCreationRounded'
import TransgenderIcon from '@mui/icons-material/Transgender'
import BookmarkIcon from '@mui/icons-material/Bookmark'

//User components
import SignOutButton from './users/SignOutButton'

const drawerWidth = 240;

const Layout = ({ children }) => {
  return (
    <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar 
            position="fixed" 
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            style={{
                background: 'linear-gradient(45deg, #9013FE 15%, #50E3C2 90%)',
            }}
        >
            <Toolbar>
                <Grid container alignItems='center'>
                    <Grid item xs={6}>
                        <Typography variant='h6' noWrap component='div'>
                            Chaturbyte
                        </Typography>
                    </Grid>
                    <Grid item xs={6} style={{textAlign: "right"}}>
                        <SignOutButton />
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
        <Drawer
            variant="permanent"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            }}
        >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
            <List>
                <ListItem key={'Dashboard'} disablePadding>
                    <ListItemButton component="a" href="/">
                        <ListItemIcon>
                            <DashboardRoundedIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Dashboard'} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={'Content'} disablePadding>
                    <ListItemButton component="a" href="/content">
                        <ListItemIcon>
                            <MovieCreationRoundedIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Content'} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={'Rooms'} disablePadding>
                    <ListItemButton component="a" href="/rooms">
                        <ListItemIcon>
                            <TransgenderIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Rooms'} />
                    </ListItemButton>
                </ListItem>

            </List>
            <Divider />
            <List>
                <ListItem key={'Bookmarks'} disablePadding>
                    <ListItemButton component="a" href="/bookmarks">
                        <ListItemIcon>
                            <BookmarkIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Bookmarks'} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            {children}
        </Box>
    </Box>
  )
}

export default Layout