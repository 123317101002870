//Import modules
import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'

//User APIs
import { isAuthenticated } from '../http/userAPI'

const Dashboard = () => {
    const [isAuth] = useState(isAuthenticated())

    useEffect (() => {
        document.title = 'Chaturbyte - dashboard'
    })

    return (
        <div>
            { isAuth ? <h1>DASHBOARD</h1>: <Navigate to={'/signin'} />}
        </div>
    )
}

export default Dashboard