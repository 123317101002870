//Import modules
import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'

//User components
import AuthForm from '../components/users/AuthForm'

//User APIs
import { isAuthenticated, handleSignIn } from '../http/userAPI'

const SignIn = () => {
    const [isAuth] = useState(isAuthenticated())

    useEffect (() => {
        document.title = 'Chaturbyte - sign in'
    })
    return (
        <Grid
            container
            spacing={0} 
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{
                background: 'linear-gradient(45deg, #9013FE 15%, #50E3C2 90%)',
                minWidth: '100%',
                minHeight: '100vh',
            }}
        >
            { !isAuth ?   
                <Grid 
                    item 
                    xs={3}
                    style={{
                        minWidth: '25%',
                        padding: 15
                    }}
                >
                    <AuthForm buttonText={'sign in'} switchText={'I do not have an account yet'} switchRoute={'/signup'} handle={handleSignIn} />
                </Grid>
                : <Navigate to={'/'} />
            }
        </Grid>
    )
}

export default SignIn