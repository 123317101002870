import React, { useEffect, useState } from 'react'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import { useNavigate } from 'react-router-dom'

//User APIs
import { handleSignOut } from '../../http/userAPI'

const Errors = ({code, message, signout}) => {

    const navigate = useNavigate()

    const [counter, setCounter] = useState(3)

    const countDown = () => {
        if (signout) {
            handleSignOut()
        }
        const intervalHandler = setInterval(() => {
            if (counter < 1) {
                clearInterval(intervalHandler)
                navigate('/')
            }
            setCounter(counter - 1)
        }, 1000)
    }

    useEffect(() => {
        countDown()
    })

    return (

        <Paper 
            elevation={1} 
            style={{
                padding: 15
            }}
        >
            <Stack 
                spacing={2}
                justifyContent="center"
                alignItems="center"
            >
                <Typography variant='h3' color='blueviolet'>{code}</Typography>
                <Typography variant='h6' color='grey'>{message}</Typography>
                <Typography variant='caption' color='GrayText'>{`Redirecting... ${counter}`}</Typography>
                <CircularProgress />
            </Stack>
        </Paper>

    )
}

export default Errors