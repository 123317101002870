//Import modules
//import { useNavigate } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'

//User APIs
import { handleSignOut } from '../../http/userAPI'

const SignOutButton = () => {
    //const navigate = useNavigate()
    const [signedOut, setSignedOut] = useState(false)
    const signOut = () => {
        handleSignOut()
        setSignedOut(!signedOut)
    }
    return (
        <div>
            { !signedOut ?
                <IconButton aria-label='logout' size='large' color='secondary' onClick={signOut}>
                    <LogoutRoundedIcon fontSize='inherit' />
                </IconButton>
                : <Navigate to={'/signin'} />
            }
        </div>
    )
}

export default SignOutButton