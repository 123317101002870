import React, { useEffect } from 'react'

const Rooms = () => {
    useEffect (() => {
        document.title = 'Chaturbyte - rooms'
    })
    return (
        <div>
            <h1>ROOMS</h1>
        </div>
    )
}

export default Rooms