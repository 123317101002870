//Import modules
import { useEffect } from 'react'
import Grid from '@mui/material/Grid'

//User components
import Errors from '../components/errors/Errors'

const NotFound = () => {


    useEffect (() => {
        document.title = 'Chaturbyte - not found'
    })
    return (
        <Grid
            container
            spacing={0} 
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{
                background: 'linear-gradient(45deg, #9013FE 15%, #50E3C2 90%)',
                minWidth: '100%',
                minHeight: '100vh',
            }}
        >
                <Grid 
                    item 
                    xs={3}
                    style={{
                        minWidth: '25%',
                        padding: 15
                    }}
                >
                    <Errors code={'404'} message={'Page not found'} signout={false}/>
                </Grid>
        </Grid>
    )
}

export default NotFound