
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Layout from '../components/Layout'

//User components
import Dashboard from './dashboard'
import Content from './content'
import Rooms from './rooms'
import Bookmarks from './bookmarks'
import SignUp from './signup'
import SignIn from './signin'
import Unauthorized from './unauthorized'
import NotFound from './notfound'

const Pages = () => {
    return (
        <Router>
                <Routes>
                    <Route exact path="/" element={<Layout><Dashboard /></Layout>} />
                    <Route exact path="/content" element={<Layout><Content /></Layout>} />
                    <Route exact path="/rooms" element={<Layout><Rooms /></Layout>} />
                    <Route exact path="/bookmarks" element={<Layout><Bookmarks /></Layout>} />
                    <Route exact path="/signup" element={<SignUp />} />
                    <Route exact path="/signin" element={<SignIn />} />
                    <Route exact path="/unauthorized" element={<Unauthorized />} />
                    <Route exact path="*" element={<NotFound />} />
                </Routes>
        </Router>
    )
}

export default Pages