//Initialization
const { REACT_APP_API_URL } = process.env

export const add = async (uuid, room) => {
    return new Promise (async (resolve, reject) => {
        try {
            const request = await fetch(`${REACT_APP_API_URL}/api/tier5/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    uuid, 
                    room
                })
            })
            const response = await request.json()
            if (response.error) {
                throw response
            }
            resolve(response)
        } catch(e) {
            console.log('Failed to add tier5 element', e.error)
            reject(e.error)
        }
    })
}

export const remove = async (uuid, room) => {
    return new Promise (async (resolve, reject) => {
        try {
            const request = await fetch(`${REACT_APP_API_URL}/api/tier5/remove`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    uuid, 
                    room
                })
            })
            const response = await request.json()
            if (response.error) {
                throw response
            }
            resolve(response)
        } catch(e) {
            console.log('Failed to remove tier5 element', e.error)
            reject(e.error)
        }
    })
}